
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import { BoxType } from "@/store/modules/BoxInfoModule"
import { BoxCategoryOption } from "@/store/modules/BoxCategoryModule"

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("套系列表", ["套系管理;box_infos"])
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      box_category_id: '',
      box_type: '',
      game_type: '',
      name: '',
      is_crash: '',
      is_increase_membership_point: '',
      enabled: ''
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_BOX_INFOS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentBoxInfos
          page.value.totalResult = store.getters.currentBoxInfoCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query()

    const submitEvent = () => {
      query()
    }

    const newBoxInfo = () => {
      router.push({ name: "box_info_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.box_category_id = ''
      formData.value.box_type = ''
      formData.value.game_type = ''
      formData.value.name = ''
      formData.value.is_crash = ''
      formData.value.is_increase_membership_point = ''
      formData.value.enabled = ''
    }

    const box_types = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_BOX_TYPES)
      .then(() => {
        box_types.value = [{key: '', value: ''}]
        box_types.value = box_types.value.concat(store.getters.currentBoxTypes)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const box_categories = ref(Array<BoxCategoryOption>())

    store
      .dispatch(Actions.GET_BOX_CATEGORIES)
      .then(() => {
        box_categories.value = [{name: '', id: ''}]
        box_categories.value = box_categories.value.concat(store.getters.currentBoxCategories)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

      const game_types = ref(Array<BoxType>())

      store
        .dispatch(Actions.GET_GAME_TYPES)
        .then(() => {
          game_types.value = store.getters.currentGameTypes
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })

    return {
      tableData,
      formData,
      submitEvent,
      newBoxInfo,
      page,
      onPageChange,
      onCancel,
      box_types,
      box_categories,
      game_types
    }
  },
})
